
import { defineComponent, onMounted } from "vue";
import AdminTable from "@/components/widgets/tables/AdminTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "admins",
  components: {
    AdminTable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      if (store.getters.currentUser.type != "super_admin") {
        router.push({ name: "dashboard" });
      }
      setCurrentPageTitle("Admin");
    });
  },
});
