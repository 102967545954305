
import { defineComponent, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import GlobalTable from "@/components/widgets/tables/GlobalTable.vue";
import DeleteAdmin from "@/components/swal/DeleteAdmin.vue";
import { useRouter } from "vue-router";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "admin_table",
  components: { GlobalTable, DeleteAdmin },
  setup() {
    let item = ref();
    let change = ref<boolean>(false);
    let updaterID = ref(null);
    let deleterData = ref({});
    let adminID = ref(null);
    let checkDeleter = ref<boolean>(false);
    let checkNotification = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const currentSort = ref<string>("");
    let search = ref<string | null>(null);
    let getDataExist = ref<boolean>(true);

    let pagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 10,
    });
    const column = ref<string>("id");
    const orderType = ref<string>("desc");
    let page = ref<number>(1);
    let perPage = ref<number>(10);
    let data = ref([]);
    const router = useRouter();

    const tableHeader = [
      {
        name: "ID",
        key: "id",
        sortable: false,
      },
      {
        name: "Date",
        key: "date",
        sortable: false,
      },
      {
        name: "First name",
        key: "first_name",
        sortable: false,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: false,
      },
      {
        name: "Username",
        key: "username",
        sortable: false,
      },
      {
        name: "Actions",
        key: "actions",
        sortable: false,
      },
    ];
    onMounted(() => {
      getAdmins(perPage.value, page.value, column.value, orderType.value);
    });

    function getAdmins(perPage, page, column, orderType) {
      search.value = null;
      loading.value = true;
      if(column === 'date'){
        column = 'created_at';
      }
      apiService
        .get(
          "",
          `?perPage=${perPage}&page=${page}&column=${column}&orderType=${orderType}`
        )
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data?.data;
            //pagination.value.page = resp.data?.meta.current_page;
            //pagination.value.total = resp.data?.meta.total;
            //pagination.value.rowsPerPage = resp.data?.meta.per_page;
            loading.value = false;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    function searchAdmins(perPage, page, query, column, orderType) {
      loading.value = true;
      apiService
        .get(
          "/search",
          `?query=${query}&perPage=${perPage}&page=${page}&column=${column}&orderType=${orderType}`
        )
        .then(function (resp) {
          data.value = resp.data.data;
          //pagination.value.page = resp.data.meta.current_page;
          //pagination.value.total = resp.data.meta.total;
          //pagination.value.rowsPerPage = resp.data.meta.per_page;
          loading.value = false;
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }
    const orderedData = (resp) => {
      column.value = resp.column;
      orderType.value = resp.sorting;
      getAdmins(perPage.value, page.value, resp.column, resp.sorting);
    };
    const setItemsPerPage = (event) => {
      page.value = 1;
      perPage.value = event.target.value;
      if (search.value != null && search.value != "") {
        searchAdmins(
          perPage.value,
          page.value,
          search.value,
          column.value,
          orderType.value
        );
      } else {
        getAdmins(perPage.value, page.value, column.value, orderType.value);
      }
    };
    const existData = async (resp) => {
      getDataExist.value = false;
      if (resp) {
        getDataExist.value = await resp;
      }
    };
    const currentPageChange = (val) => {
      page.value = val;
      //pagination.value.page = val;
      if (search.value != null && search.value != "") {
        searchAdmins(
          perPage.value,
          val,
          search.value,
          column.value,
          orderType.value
        );
      } else {
        getAdmins(perPage.value, val, column.value, orderType.value);
      }
    };

    const setSearchValue = (event) => {
      search.value = event.target.value;
      if (!search.value?.trim()) {
        getAdmins(perPage.value, page.value, column.value, orderType.value);
        return;
      }
      searchAdmins(
        perPage.value,
        page.value,
        search.value,
        column.value,
        orderType.value
      );
    };

    const checkModal = (resp) => {
      if (resp.status == "update") {
        let array = JSON.parse(JSON.stringify(data.value));
        let foundIndex = array.findIndex((x) => x.id == resp.data.id);
        array[foundIndex] = resp.data;
        data.value = new Proxy(array, {});
      } else if (resp.status == "insert") {
        search.value = null;
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      } else if (resp.status == "deleted") {
        search.value = null;
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      }
    };

    const deleteAdmin = (id, adminname) => {
      checkDeleter.value = !checkDeleter.value;
      deleterData.value = {
        id: id,
        adminname: adminname,
      };
    };

    return {
      loading,
      tableHeader,
      data,
      setItemsPerPage,
      currentPageChange,
      pagination,
      perPage,
      page,
      search,
      setSearchValue,
      currentSort,
      checkModal,
      item,
      updaterID,
      checkDeleter,
      deleterData,
      change,
      deleteAdmin,
      checkNotification,
      adminID,
      getDataExist,
      orderedData,
      existData,
      orderType,
      column,
    };
  },
});
